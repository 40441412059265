<template>
  <main class="services">
    <div class="container">
      <heading-1>Behandlinger og priser</heading-1>
      <p class="mb-24">
        Her kan du se hvilke behandlinger vi tilbyder inden for
        <router-link to="haarfjerning-med-laser" class="link"
          >laser hårfjerning</router-link
        >
        . Vi tilbyder en gratis konsultation inden dit første besøg hos os. Det
        anbefales at du læser vores
        <router-link to="/faq" class="link">F.A.Q.</router-link>
        hvor du kan få svar på det mest stillede spørgsmål.
      </p>
      <heading-2>Prisoversigt</heading-2>
      <div class="flex justify-between">
        <div class="w-full">
          <table class="w-full text-black font-medium">
            <tbody>
              <template
                v-for="(service, index) in $store.getters.getData.service"
                :key="service.id"
              >
                <tr>
                  <td
                    class="text-lg align-top py-4"
                    :class="
                      index == $store.getters.getData.service.length - 1
                        ? ''
                        : 'border-b-2 border-dotted'
                    "
                  >
                    {{ service.name }}
                    <span
                      v-if="service.description"
                      class="block text-sm text-gray-400 font-light pt-2"
                      >{{ service.description }}</span
                    >
                  </td>
                  <td
                    class="text-lg align-top py-4 pl-4 text-right whitespace-nowrap"
                    :class="
                      index == $store.getters.getData.service.length - 1
                        ? ''
                        : 'border-b-2 border-dotted'
                    "
                  >
                    {{ service.price }} kr.
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Heading1 from "../components/Heading1.vue";
import Heading2 from "../components/Heading2.vue";
export default {
  name: "Services",
  components: { Heading1, Heading2 },
};
</script>
